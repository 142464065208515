<template>
  <div class="contribute">
    <div class="top">
      <div class="left">
        <div class="item" :class="current == index && 'active'" v-for="(item, index) of leftMenu" :key="index"
          @click="handleClick(index, item)">
          <van-badge :content="item.total" max="99" v-if="item.status && !item.hiddentotal">
            <div>
              {{ item.fee_name }}
            </div>
          </van-badge>
          <div v-else>
            {{ item.fee_name }}
          </div>
          <!-- <div class="flag">10</div> -->
        </div>
      </div>
      <div class="right">
        <div v-if="clickItem.type == 0">
          <div class="item" v-for="(item, index) of dataSource" :key="index">
            <div class="top-info">
              <div v-for="(itemz, index) of filedList" :key="index">
                <span v-if="!(itemz.filed == 'contract_no' && (isTemporary || item.bill_key == 20))" class="label">{{
                  itemz.name }}</span>
                <span v-if="!(itemz.filed == 'contract_no' && (isTemporary || item.bill_key == 20))"
                  :class="itemz.filed == 'contract_no' ? 'green' : 'black'">
                  {{ item[itemz.filed] }}
                </span>
              </div>
            </div>
            <div class="fee-info">
              <div class="fee-info__item" style="width: 100%;">
                <span class="label">缴费周期：</span>
                <span class="black">{{ item.property_time }} ~ {{ item.property_end_time }}</span>
              </div>
              <div class="fee-info__item" style="width: 100%;">
                <span class="label">商铺号：</span>
                <span class="black">{{ item.merchants_stall_number }}</span>
              </div>
              <div class="fee-info__item">
                <span class="label">特殊减免：</span>
                <span class="black">{{ balanceInfo.coupon_total_price || 0 }}</span>
              </div>
              <div class="fee-info__item">
                <span class="label">周期减免：</span>
                <span class="red">{{ item.derate?.reduce_price || 0 }}</span>
              </div>
              <div class="fee-info__item">
                <span class="label">折扣：</span>
                <span class="black">{{ balanceInfo.activity_discount || 0 }}</span>
              </div>
              <div class="fee-info__item">
                <span class="label">折扣减免：</span>
                <span class="red">{{ item.give_discount || 0 }}</span>
              </div>
              <div class="fee-info__item">
                <span class="label">余额：</span>
                <span class="red">{{ balanceInfo.balance || 0 }}</span>
              </div>
              <div class="fee-info__item">
                <span class="label">余额抵扣：</span>
                <span class="red">{{ item.balance_price || 0 }}</span>
              </div>
              <!-- <div class="fee-info__item" v-for="(itemz, index) of feeFiledList" :key="index">
                <span class="label">{{ itemz.name }}</span>
                <span class="red" v-if="index == 1">{{ item.derate?.reduce_price || 0 }}</span>
                <span :class="index == 3 || index == 5 ? 'red' : 'black'" v-else>
                  {{ index == 3 || index == 5 ? item[itemz.filed] :
                    balanceInfo[itemz.filed] ?? 0 }}
                </span>
              </div> -->
            </div>
            <div class="pay-info">
              <div>
                <span class="label">应缴:</span>
                <span class="black">{{ item.finalMoney }}</span>
              </div>
              <div class="subMit">
                <span class="unit">￥</span>
                <!-- <van-field
                  class="input-box"
                  v-model="item.payment_money"
                  type="number"
                  :disabled="item.bill_key == 9"
                > -->
                <van-field class="input-box" v-model="item.payment_money" type="number" disabled>
                </van-field>
                <div class="button" @click="hanldePay(item)">支付</div>
              </div>
            </div>
          </div>
        </div>
        <div class="intelligentRecharge" v-if="clickItem.type == 1">
          <div class="title">
            <div></div>
            <div class="name">智能水表充值</div>
            <div class="record" @click="detailRecord(clickItem.type)">记录</div>
          </div>
          <div class="intelligentRechargeBox">
            <div class="list">
              <van-field v-model="params.meter_number" type="number" @blur="getMeterNumbers" label-width="1.5rem"
                label="表号" placeholder="请输入表号">
                <template #button>
                  <van-button size="small" type="primary" class="button" @click="hanldeShowPop">选择</van-button>
                </template>
              </van-field>
            </div>
            <!-- <div class="list">
              <van-field
                v-model="params.degree"
                type="digit"
                @input="calculate"
                label-width="1.5rem"
                label="吨数"
                placeholder="请输入充值吨数"
              >
                <template #extra>吨</template>
              </van-field>
            </div> -->
            <div class="list">
              <van-field v-model="params.price" label-width="1.5rem" label="单价" :readonly="true" placeholder="单价">
                <template #extra>元</template>
              </van-field>
            </div>
            <div class="list">
              <van-field v-model="params.pay_price" label-width="1.5rem" label="充值金额" placeholder="请输入充值金额">
                <template #extra>元</template>
              </van-field>
            </div>
          </div>
          <div class="btn">
            <div class="button" @click="rechargePay">充值</div>
          </div>
        </div>
        <div class="intelligentRecharge" v-if="clickItem.type == 2">
          <div class="title">
            <div></div>
            <div class="name">智能电表充值</div>
            <div class="record" @click="detailRecord(clickItem.type)">记录</div>
          </div>
          <div class="intelligentRechargeBox">
            <div class="list">
              <van-field v-model="params.meter_number" type="number" label-width="1.5rem" @blur="getMeterNumbers"
                label="表号" placeholder="请输入表号">
                <template #button>
                  <van-button size="small" type="primary" class="button" @click="hanldeShowPop">选择</van-button>
                </template>
              </van-field>
            </div>
            <!-- <div class="list">
              <van-field
                v-model="params.degree"
                type="digit"
                @input="calculate"
                label-width="1.5rem"
                label="度数"
                placeholder="请输入充值度数"
              >
                <template #extra>度</template>
              </van-field>
            </div> -->
            <div class="list">
              <van-field v-model="params.price" label-width="1.5rem" label="单价" :readonly="true" placeholder="单价">
                <template #extra>元</template>
              </van-field>
            </div>
            <div class="list">
              <van-field v-model="params.pay_price" label-width="1.5rem" label="充值金额" placeholder="请输入充值金额">
                <template #extra>元</template>
              </van-field>
            </div>
          </div>
          <div class="btn">
            <div class="button" @click="rechargePay">充值</div>
          </div>
        </div>
        <EmptyItem v-if="!dataSource?.length && clickItem.type == 0"></EmptyItem>
      </div>
    </div>
    <van-popup v-model:show="show" round position="bottom">
      <van-picker title="选择表号" :columns="columnsData" :columns-field-names="customFieldName" @confirm="onConfirm"
        @cancel="show = false" />
    </van-popup>
    <!-- <div class="bottom" @click="goDetail">
      <div class="label">查看历史账单</div>
      <div>></div>
    </div> -->
  </div>
</template>

<script setup>
import { ref, inject, onMounted, reactive, watch, watchEffect, nextTick } from 'vue';
import { Toast } from 'vant';
import EmptyItem from '../../unit/components/empty-item.vue';
import { filedList, feeFiledList } from './config';
import {
  getFeeListById,
  addPayPrice,
  getAllFee,
  getMeterNumber,
  getMeterList,
  setRechargePay
} from '@/service/contribute';

const localdata = window.localStorage.getItem('propertyUserInfo') ?? {};
const { router, route } = inject('ctx');
const leftMenu = ref([]);
const dataSource = ref([]); //右侧数据key 为左边列表index
const couponInfo = ref([]); //减免信息
let globalDerate = ref({}); //商户上的减免信息
const balanceInfo = ref({}); //余额信息
const clickItem = ref({});
const current = ref(0);
const show = ref(false);
const customFieldName = ref({
  text: 'name',
  value: 'meter_number'
});
let columnsData = ref([]);
const params = reactive({
  meter_number: '', // 表号
  meter_type: '', // 水1 电2
  pay_price: 0, // 总金额
  price: 0, // 单价
  pay_type: 10, // 支付类型
  degree: '' // 度数
});
const isTemporary = JSON.parse(localdata).isTemporary == 1;
const { id } = route.query;
// 充值记录
const detailRecord = (type) => {
  router.push({ path: `/contribute/rechargeRecord`, query: { type } });
};

const checkIntNumber = (number) => {
  if (isNaN(number)) return false;
  if (number <= 0) return false;

  let decimalPlaces = 0;
  if (number % 1 !== 0) {
    decimalPlaces = number.toString().split('.')[1].length;
  }
  if (decimalPlaces > 2) {
    return false;
  }

  return true;
};
/**
 * 充值支付
 */
const rechargePay = () => {
  if (!params.meter_number) {
    Toast('请输入表号');
    return;
  }
  // if (!params.degree) {
  //   Toast('请输入度数');
  //   return;
  // }

  if (!checkIntNumber(params.pay_price)) {
    Toast('充值金额异常');
    return;
  }
  if (!params.pay_price) {
    Toast('充值金额不能为0');
    return;
  }
  setRechargePay(params).then((res) => {
    const url = res?.data?.payment_url?.url ?? res?.payment_url;
    window.location.href = url;
    params = {
      meter_number: '', // 表号
      meter_type: clickItem.value.type, // 水1 电2
      pay_price: 0, // 总金额
      price: 0, // 单价
      pay_type: 10, // 支付类型
      degree: ''
    };
  });
};
// 计算总金额
const calculate = (event) => {
  if (!params.degree) {
    // Toast('请输入要充值的度数');
    return;
  }
  if (!params.price) {
    // Toast('未查询到单价');
    return;
  }
  params.pay_price = Number((Number(params.degree) * Number(params.price)).toFixed(2));
};
// 根据表号获取单价并且查询表号是否存在
const getMeterNumbers = (event) => {
  if (event.target.value) {
    getMeterNumber({
      meter_number: event.target.value
    }).then((res) => {
      if (res.data.code == 1) {
        params.price = res.data.price;
        calculate();
      } else {
        Toast(res.data.msg);
        params.price = 0;
      }
    });
  }
};
const onConfirm = (event) => {
  params.meter_number = event.meter_number;
  show.value = false;
  getMeterNumbers({
    target: {
      value: event.meter_number
    }
  });
};
const goIndexById = () => {
  if (!id) {
    handleClick(0, leftMenu.value[0]);
    return;
  }
  for (const index in leftMenu.value) {
    if (leftMenu.value[index].fee_id == id) {
      handleClick(index, leftMenu.value[index]);
      break;
    }
  }
};
//获取左边菜单
const handleLeftMenus = async () => {
  // const result = await getLeftMenus();
  const { data: result } = await getAllFee();
  const tempArry = [];
  result?.forEach((item) => {
    if (item.contract_fee_type == 20) return;
    tempArry.push({
      fee_name: item.contract_fee_name,
      fee_id: item.contract_fee_id,
      bill_key: item.contract_fee_type,
      total: item.contract_fee_total,
      status: item.contract_fee_status,
      type: 0
    });
  });
  tempArry.push({
    fee_name: '智能电表充值',
    fee_id: '',
    bill_key: '',
    total: '',
    status: false,
    type: 2
  });
  tempArry.push({
    fee_name: '智能水表充值',
    fee_id: '',
    bill_key: '',
    total: '',
    status: false,
    type: 1
  });
  leftMenu.value = tempArry;

  goIndexById();
};

const getMeterLists = (type) => {
  getMeterList({
    meter_type: type
  }).then((res) => {
    if (res.data && res.data.list) {
      let merchantsName = JSON.parse(localdata).merchantsName;
      const reg = /^./;
      merchantsName = merchantsName.replace(reg, '*');
      columnsData.value = res.data.list.map((item) => {
        item.name = `${item.merchants_stall_name}—${item.meter_number}—${merchantsName}`;
        return item;
      });
    } else {
      columnsData.value = [];
    }
  });
};

const goDetail = () => {
  router.push({ path: '/contribute/record' });
};
const initParams = () => {
  params.degree = '';
  params.price = '';
  params.pay_price = '';
  params.meter_number = '';
};
const handleClick = async (index, item) => {
  if (item.type == 1) {
    // 智能水表
    initParams();
    current.value = index;
    clickItem.value = item;
    params.meter_type = item.type;

    getMeterLists(item.type);
  } else if (item.type == 2) {
    // 智能电表
    initParams();
    current.value = index;
    clickItem.value = item;
    params.meter_type = item.type;
    getMeterLists(item.type);
  } else {
    // 其他缴费项
    clickItem.value = item;
    current.value = index;
    const params = {
      bill_key: item.bill_key,
      fee_id: item.fee_id,
      merchants_id: JSON.parse(localdata).merchantsId
    };
    const res = await getFeeListById(params);
    item.hiddentotal = true;
    let reducePrice = 0;
    let coupon_ids = [];
    if (res.data.list) {
      if (res.data.coupon_bill_list) {
        // 特殊减免
        res.data.coupon_bill_list.forEach((list) => {
          if (list.bill_id == 0) {
            globalDerate.value = list;
            reducePrice += Number(list.reduce_price);
            coupon_ids.push(list.coupon_id);
          }
        })
      }
      res.data.list.forEach((item) => {
        item.derateTotal = 0;
        item.coupon_ids = [];
        item.parenitIds = coupon_ids;
        item.couponTotalPrice = 0;
        if (res.data.coupon_bill_list) {
          res.data.coupon_bill_list.forEach((list) => {
            if (list.bill_id == item.bill_id) {
              item.derate = list;
              item.derateTotal += Number(list.reduce_price);
              item.coupon_ids.push(list.coupon_id);
            }
          })
        }
      })
    }
    dataSource.value = res.data.list ?? [];

    // couponInfo.value = res.data?.coupon_bill_list ?? [];
    balanceInfo.value = res.data?.merchants_balance ?? {};
    // const preValue = 0;
    // const tempArry = res.data?.coupon_bill_list?.map((item) => item.reduce_price - item.deduction_price) ?? [];
    // balanceInfo.value.coupon_total_price = tempArry.reduce(
    //   (previousValue, currentValue) => previousValue + currentValue,
    //   preValue
    // );
    balanceInfo.value.coupon_total_price = Number(reducePrice.toFixed(2)) || 0;
    balanceInfo.value.activity_discount = res.data?.property_setting?.ActivityDiscount ?? '无折扣';
    hanldeCalc();
  }
};
//计算抵扣等金额
const hanldeCalc = () => {
  dataSource.value.forEach((item) => {
    const result = hanldeCoupon(
      item.key_pay_price,
      item?.derate?.reduce_price || 0,
      balanceInfo.value.activity_discount,
      balanceInfo.value.balance ?? 0,
      balanceInfo.value.coupon_total_price,
    );
    // if (result.finalMoney > 0) {
    //   item.couponTotalPrice = balanceInfo.value.coupon_total_price;
    // } else {
    //   item.couponTotalPrice = Number(parseFloat(balanceInfo.value.coupon_total_price - result.give_discount + result.balance_price + result.coupon_price).toFixed(2));
    // }
    let price = result.give_discount + result.balance_price + result.coupon_price;
    item.couponTotalPrice = Number(parseFloat(balanceInfo.value.coupon_total_price - (item.key_pay_price - price)).toFixed(2));
    // item.coupon_price = result.coupon_price;
    item.give_discount = result.give_discount;
    item.balance_price = result.balance_price;
    item.finalMoney = result.finalMoney;
    item.payment_money = result.finalMoney;
  });
};
/**
 *
 * @param {*} payPrice 需要缴费的金额
 * @param {*} coupon 总的减免金额
 * @param {*} activityDiscount 折扣金额
 * @param {*} balance 余额
 * @param {*} special 特殊减免
 */
const hanldeCoupon = (payPrice, coupon, activityDiscount, balance, special) => {
  const result = {
    coupon_price: 0, //减免抵扣
    give_discount: 0, //折扣减免
    balance_price: 0, //余额抵扣
    finalMoney: payPrice
  };
  const discount = activityDiscount == '无折扣' ? 1 : activityDiscount;
  //减免金额大于付款金额
  if (payPrice <= coupon) {
    result.coupon_price = payPrice;
    return result;
  }
  result.coupon_price = coupon;
  const lastMoney = payPrice - coupon;
  result.give_discount = Math.round(lastMoney * (1 - discount) * 100) / 100;
  const nextMoney = lastMoney - result.give_discount;
  if (nextMoney <= balance) {
    result.balance_price = Math.round(nextMoney * 100) / 100;
  } else {
    result.balance_price = balance;
  }
  result.finalMoney = nextMoney - special - balance >= 0 ? Math.round((nextMoney - special - balance) * 100) / 100 : 0;
  return result;
};
/**
 * 提交付费
 */
const hanldePay = (item) => {
  if (item.payment_money < item.finalMoney) {
    Toast('缴费金额小于应缴费金额');
    return;
  }
  const data = {
    market_id: JSON.parse(localdata).marketId,
    merchants_id: JSON.parse(localdata).merchantsId,
    payment_money: item.payment_money,
    pay_type: 10, //30为现金收费 10微信 TODO后续修改实际收费方式
    bill_type: 20, //线上收费
    fee_setting_list: []
    // fee_setting_list: [
    //   {
    //     contract_fee_id: clickItem.value.fee_id,
    //     contract_fee_name: item.bill_name,
    //     bill_key: item.bill_key,
    //     bill_ids: [item.bill_id],
    //     balance_price: item.balance_price,
    //     coupon_ids: item.derate ? [item.derate.coupon_id] : [],
    //     coupon_total_price: balanceInfo.value.coupon_total_price,
    //     activity_discount: balanceInfo.value.activity_discount == '无折扣' ? 1 : balanceInfo.value.activity_discount,
    //     give_discount: item.give_discount,
    //     much_balance_price: Math.round((item.payment_money - item.finalMoney) * 100) / 100,
    //     payment_money: item.payment_money
    //   }
    // ]
  };
  data.fee_setting_list.push({
    balance_price: balanceInfo.value.balance,
    balance_price_used: item.balance_price,
    children: [
      {
        bill_id: item.bill_id,
        contract_id: item.contract_id,
        contract_no: item.contract_no,
        coupon_id: item.coupon_ids || [],
        derate_price: item.derateTotal,
        discount: balanceInfo.value.activity_discount,
        discount_price: item.give_discount,
        key_pay_price: item.key_pay_price,
        merchants_stall_number: item.merchants_stall_number,
        pay_amount: item.key_pay_price - item.give_discount
      }
    ],
    contract_fee_id: clickItem.value.fee_id,
    contract_fee_name: item.bill_name,
    coupon_id: item.parenitIds || [],
    coupon_total: balanceInfo.value.coupon_total_price,
    coupon_used: item.couponTotalPrice,
    give_discount: item.give_discount,
    payment_money: item.payment_money,
    payable: item.payment_money
  })
  addPayPrice(data).then((res) => {
    const url = res?.data?.payment_url?.url ?? res?.payment_url;
    window.location.href = url;
    handleClick(current.value, leftMenu.value[current.value]);
  });
};
onMounted(() => {
  handleLeftMenus();
});

const hanldeShowPop = () => {
  show.value = true;
};
</script>

<style lang="scss" scoped>
.contribute {
  height: 100vh;

  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;

  .top {
    flex: 1;
    display: flex;
    overflow: auto;
    padding: 16px 32px 0 0;
    box-sizing: border-box;

    .left {
      font-size: 28px;
      width: 156px;
      margin-right: 20px;
      box-sizing: border-box;
      height: 100%;
      overflow-y: auto;

      .item {
        min-height: 96px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;

        .flag {
          position: absolute;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          color: rgba(#fd6336, 0.8);
          right: 10px;
          top: 10px;
          transform: scale(0.8);
          z-index: 2;
        }
      }

      .active {
        background-color: #fff;
        color: #85c226;
      }
    }

    .right {
      box-sizing: border-box;
      font-size: 26px;
      flex: 1;
      overflow: auto;
      height: 100%;

      .item {
        background-color: #fff;
        padding: 0 20px;
        border-radius: 10px 10px 10px 10px;
        margin-bottom: 20px;

        .top-info {
          display: flex;
          flex-wrap: wrap;

          >div {
            width: 100%;
            margin: 10px 0;
          }

          .content {
            color: #000000;
          }
        }

        .fee-info {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          height: 222px;
          border-top: 1px solid rgba(0, 0, 0, 0.05);
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);

          &__item {
            width: 50%;
          }
        }

        .pay-info {
          height: 184px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          .subMit {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .unit {
              position: absolute;
              left: 25px;
              font-size: 36px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
            }

            .input-box {
              width: 350px;
              height: 80px;
              padding-left: 24px;
              display: flex;
              align-items: center;
              background: #ffffff;
              border-radius: 200px 200px 200px 200px;
              border: 2px solid rgba(0, 0, 0, 0.1);

              .pay-number {
                font-size: 36px;
                font-weight: bold;
                color: #85c226;
              }
            }

            .button {
              width: 140px;
              height: 80px;
              line-height: 80px;
              text-align: center;
              background: #85c226;
              border-radius: 200px 200px 200px 200px;
              opacity: 1;
              color: #ffffff;
            }
          }
        }

        .pay-info-done {
          height: 130px;

          display: flex;
          flex-wrap: wrap;
          align-items: center;

          >div {
            width: 50%;

            &:last-child {
              width: 100%;
            }
          }
        }
      }

      .intelligentRecharge {
        background-color: #fff;
        padding: 0 20px 40px 20px;
        border-radius: 10px 10px 10px 10px;

        .title {
          display: flex;
          justify-content: space-between;
          height: 80px;
          line-height: 80px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);

          div {
            flex: 1;
          }

          .name {
            flex: 2;
            font-size: 40px;
            font-weight: bold;
            color: #000000;
            text-align: center;
          }

          .record {
            color: #333333;
            text-align: right;
          }
        }

        .intelligentRechargeBox {
          .list {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);

            .button {
              width: 100%;
              text-align: center;
              border-radius: 10px;
              color: #ffffff;
            }
          }
        }

        .btn {
          display: flex;
          justify-content: center;
          margin: 40px 0 0;

          .button {
            width: 80%;
            height: 80px;
            line-height: 80px;
            text-align: center;
            background: #85c226;
            border-radius: 200px 200px 200px 200px;
            opacity: 1;
            color: #ffffff;
          }
        }
      }
    }
  }

  .bottom {
    height: 96px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: #85c226;
  }

  :deep(.scrollbox) {
    padding-top: 0;
  }

  :deep(.empty-item) {
    height: 100%;
  }
}

.red {
  color: #fd6336;
}

.green {
  color: #85c226;
}

.black {
  color: #000000;
}

.label {
  margin-right: 5px;
}

:deep(.van-badge--top-right) {}

:deep(.van-badge) {}
</style>
