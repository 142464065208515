const filedList = [
  {
    name: '合同编号:',
    filed: 'contract_no',
    color: '#85C226'
  },

  {
    name: '约定时间:',
    filed: 'property_time'
  },
  {
    name: '缴费金额:',
    filed: 'key_pay_price'
  }
];
const feeFiledList = [
  {
    name: '特殊减免:',
    filed: 'coupon_total_price'
  },
  {
    name: '周期减免:',
    filed: 'coupon_price'
  },
  {
    name: '折扣:',
    filed: 'activity_discount'
  },
  {
    name: '折扣减免:',
    filed: 'give_discount'
  },
  {
    name: '余额:',
    filed: 'balance'
  },
  {
    name: '余额抵扣:',
    filed: 'balance_price'
  }
];
const getType = (type) => {
  let name = 's';
  switch (type) {
    case 9:
      name = '日常收费';
      break;
    case 10:
      name = '水';
      break;
    case 20:
      name = '电';
      break;
    case 30:
      name = '气';
      break;
    case 40:
      name = '租金';
      break;
    default:
      name = '';
      break;
  }

  return name;
};
export { filedList, feeFiledList, getType };
